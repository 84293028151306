import { Component, EventEmitter, Input, Output } from '@angular/core';
import {NgClass} from "@angular/common";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
export enum TButtonsSizes{
  SMALL_REGULAR = 'small', // for small buttons with regular style
  MEDIUM_REGULAR = 'medium',// for medium buttons with regular style
  BIG_REGULAR = 'big', // for big buttons with regular style
  SMALL_FIT_CONTENT = 'small-fit',// for buttons which should fit parent content
  MEDIUM_FIT_CONTENT = 'medium-fit',// for buttons which should fit parent content
  BIG_FIT_CONTENT = 'big-fit',// for buttons which should fit parent content
}
export enum TButtonsTypes{
  SUCCESS = 'small', // button styling success
  DANGER = 'medium',// button styling danger
  PRIMARY = 'big', // button primary
  SECONDARY = 'small-fit',// button secondary
}
@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    TranslateModule
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() type: TButtonsTypes = TButtonsTypes.PRIMARY;
  @Input() size: TButtonsSizes = TButtonsSizes.SMALL_REGULAR;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() link:string = '';
  @Input() route: any;

  clickButton() {
    this.onClick.emit();
  }

  get detectCase(): any {
    return {
      'bg-primary hover:bg-primary-hover ': this.type === TButtonsTypes.PRIMARY && !this.disabled,
      'bg-reject text-white hover:bg-reject-hover border-reject': this.type === TButtonsTypes.DANGER && !this.disabled,
      'font-light hover:bg-secondary-hover': this.type === TButtonsTypes.SECONDARY && !this.disabled,
      'bg-success text-black border-success  hover:bg-success-hover': this.type === TButtonsTypes.SUCCESS && !this.disabled,
      'bg-primary-disabled': this.type === TButtonsTypes.PRIMARY && this.disabled,
      'bg-reject-disabled': this.type === TButtonsTypes.DANGER && this.disabled,
      'bg-success-disabled': this.type === TButtonsTypes.SUCCESS && this.disabled,
      'bg-secondary-disabled': this.type === TButtonsTypes.SECONDARY && this.disabled,
      'text-white': this.type === TButtonsTypes.PRIMARY || this.type === TButtonsTypes.SUCCESS || this.type === TButtonsTypes.DANGER,
      'border-primary': this.type === TButtonsTypes.PRIMARY || this.type === TButtonsTypes.SECONDARY,
      'text-18 rounded-md xl:min-w-100': this.size === TButtonsSizes.SMALL_REGULAR || this.size === TButtonsSizes.SMALL_FIT_CONTENT,
      'text-20 rounded-lg xl:min-w-260': this.size === TButtonsSizes.MEDIUM_REGULAR || this.size === TButtonsSizes.MEDIUM_FIT_CONTENT,
      'text-32 rounded-lg xl:min-w-420 min-w-72': this.size === TButtonsSizes.BIG_REGULAR || this.size === TButtonsSizes.BIG_FIT_CONTENT
    };
  }
}
