@if(!route?.length && !link.length){
  <button class="inline-block text-center
      border border-solid
      focus:outline-none
      rounded
      font-bold
      py-1.5 px-3.5
      cursor-pointer"
     [ngClass]="detectCase"
     [disabled]="disabled"
     (click)='clickButton()'>
    {{text | translate}}
  </button>
} @else if (link){
  <a class="inline-block text-center
      border border-solid
      focus:outline-none
      rounded
      font-bold
      py-1.5 px-3.5
      cursor-pointer"
     [ngClass]="detectCase"
     [href]="link">
    {{text | translate}}
  </a>
} @else if (route){
  <a class="inline-block text-center
      border border-solid
      focus:outline-none
      rounded
      font-bold
      py-2 px-3.5
      cursor-pointer"
     [ngClass]="detectCase"
     [routerLink]="route">
    {{text | translate}}
  </a>
}

